.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signin-form {
    width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.signup-form {
    width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.inputs {
    width: 500px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.signup-inputs {
    width: 800px;
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.avatar {
    height: 150px;
    display: flex;
}

.logo-avatar {
    position: relative;
    top: -80px;
    left: 20px;
    width: 300px;
    height: 300px
}

.input {
    border: 1px solid green;
    width: 350px;
}

.wide-input {
    width: 750px;
}

.therapist-check-box {
    width: 740px;
    height: 50px;
}

.button {
    width: 250px;
}

.sign-mode {
    height: 50px;
    padding-top: 20px;
}

.switch-text {
    color: blue;
    text-decoration: underline;
}

.error-div {
    height: 20px;
}

.error-label {
    color: red;
}