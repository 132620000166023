.therapist-container {
    width: 100%;
}

.patient-title {
    display: flex;
    justify-content: space-between;
    font-size: 30px;
}

.patient-title-buttons {
    position: absolute;
    top: 115px;
    z-index: 999;
    left: 12px;
}

.patient-table {
    margin-top: 10px;
}

.graph-container {
    width: 100%;
    height: 500px;
}

.data-div {
    margin-top: 10px;
    display: flex;
    align-items: center;
    margin-top: 50px; 
    width: 100%; 
    height: 50px; 
}

.summery-button {
    width: 200px;
    height: 40px;
}

.fft-text {
    margin-left: 20px;
}

.svg-div {
    margin-top: 10px;
}

.game-dialog {
    border-top: 1px solid;
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.games-dialog-div {
    width: 90%;
    height: 200px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.game-dialog-div {
    width: 90%;
    height: 80px;
    border: 1px solid #c3c3c3;
    box-shadow: 5px 5px #f3f3f3;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.game-dialog-label {
    font-size: 22px;
    font-weight: 500;
    width: 140px;
    text-align: center;
    cursor: pointer;
}

.dialog-butttons {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.dialog-buttton {
    width: 100px;
}

.progress-dialog {
    height: 700px;
}

.graph-dialog-butttons {
    margin: 0px auto;
    width: 1000px;
    display: flex;
    justify-content: space-between;
}

.graph-dialog-buttton {
    margin-right: 30px;
    height: 40px;
    width: 180px;
    display: flex;
    justify-content: space-around;
}

.hull-data {
    width: 300px;
    height: 150px;
    position: absolute;
    top: 115px;
    display: flex;
    flex-direction: column;
}

.hull-data-label {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
}

.pow-number {
    font-size: 10px;
    position: relative;
    bottom: 5px;
}

.hulls_div {
    width: 1100px;
    margin: -50px auto;
}

.hulls-title {
    position: relative;
    top: 80px;
    right: 60px;
    width: 800px;
    margin: 0px auto;
    display: flex;
    justify-content: space-around;
    font-size: 20px;
}

.hull-title1 {
    color: green;
}

.hull-title2 {
    color: red;
}