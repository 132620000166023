.therapist-container {
    width: 100%;
}

.patient-title {
    font-size: 30px;
}

.patient-table {
    max-height: 540px;
    margin-top: 10px;
}

.game-dialog {
    border-top: 1px solid;
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.games-dialog-div {
    width: 90%;
    height: 200px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.game-dialog-div {
    width: 90%;
    height: 80px;
    border: 1px solid #c3c3c3;
    box-shadow: 5px 5px #f3f3f3;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.game-dialog-label {
    font-size: 22px;
    font-weight: 500;
    width: 140px;
    text-align: center;
    cursor: pointer;
}

.dialog-butttons {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.dialog-buttton {
    width: 100px;
}