.graph-container {
    background: white;
    height: 1000px;
}

.bottons-container {
    width: auto;
    height: 50px;
    display: flex;
    justify-content: center;
    margin: 20px auto;
}

.game-bottons-container {
    display: flex;
    position: fixed;
    bottom: 15px;
    right: 15px;
}

.range-container {
    width: 1200px;
    height: 70px;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
}

.range-title {
    width: 300px;
}

.graph-button {
    width: 200px;
}

.status_container {
    border: 1px solid black;
    height: 300px;
    margin-top: 100px;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.summery-graph {
    width: 100px;
}

.summery-button {
    margin-left: 25px;
    margin-top: 25px;
    width: 250px;
}

.summery-actions {
    /* margin-top: 10px; */
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.feet-pos-container {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.feet-title {
    font-size: 18px;
}

.feet-radios {
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: space-around;
}

.away-pos {
    cursor: pointer;
    width: 130px;
    height: 80px;
    background-image: url('../../../assets/feet-pos/away.JPG');
    background-repeat: no-repeat;
    background-color: #f5f5f5;
    margin: 0 5px;
    background-position: center;
}

.normal-pos {
    cursor: pointer;
    width: 130px;
    height: 80px;
    background-image: url('../../../assets/feet-pos/normal.JPG');
    background-repeat: no-repeat;
    background-color: #f5f5f5;
    margin: 0 5px;
    background-position: center;
}

.halfTandam-pos {
    cursor: pointer;
    width: 130px;
    height: 80px;
    background-image: url('../../../assets/feet-pos/half-tandam.JPG');
    background-repeat: no-repeat;
    background-color: #f5f5f5;
    margin: 0 5px;
    background-position: center;
}

.close-pos {
    cursor: pointer;
    width: 130px;
    height: 80px;
    background-image: url('../../../assets/feet-pos/close.JPG');
    background-repeat: no-repeat;
    background-color: #f5f5f5;
    margin: 0 5px;
    background-position: center;
}

.debug-label {
    width: 150px;
    margin-left: 50px;
}

.record-time-timer {
    text-align: center;
}

.board-indicator {
    width: 400px;
    height: 260px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

.board-indicator-point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
}

.board-indicator-container {
    text-align: center;
}