.header-bar {
    background-color: #c3c3c3;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo,
.user {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    margin-left: 16px;
    width: 300px;
}

.logo-img {
    width: 60px;
    height: 60px;
}

.header-label {
    font-size: 36px;
    font-family: 'Brush Script MT', cursive;
    width: 230px;
}


.links {
    width: 300px;
    display: flex;
    justify-content: space-between;
}

.link {
    cursor: pointer;
    color: black;
    text-decoration: none;
    font-weight: 600;
}

.selected-link {
    color: purple;
    font-weight: 700;
    text-decoration: none;
}

.user {
    margin-right: 16px;
    cursor: pointer;
    width: auto;
    margin-left: 30px;
}

.user-name {
    text-transform: capitalize;
    margin-right: 5px;
    font-size: 20px;
}

/* main {
    margin: 16px;
} */

.user-dialog {
    border-top: 1px solid;
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.user-dialog-p {
    width: 90%;
    margin-top: 10px;
    font-size: 14px;
}

.dialog-butttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.dialog-buttton {
    width: 100px;
}